.meet__our__team{
 z-index: 1;
margin:-7rem 0 1rem 0;
}


@media screen and (max-width: 650px) {
    .meet__our__team{
        z-index: 1;
       margin:-4rem 0 1rem 0;
   }
}
  