.mot__style{
    width: 100%;
}

.glow__text{
    color: #fff;
    text-align: center;
    /* animation: glow 1s ease-in-out infinite alternate; */
    text-shadow: 0 0 10px #ffd700, 0 0 10px #ffd700;
    /* 0 0 10px #ffd700, 0 0 10px #ffd700, 0 0 10px #ffd700, 0 0 10px #ffd700, 0 0 10px #ffd700, 0 0 10px #ffd700; */

}
.image__front{
    z-index: 1;
}