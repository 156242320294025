/* @import url("https://use.fontawesome.com/releases/v5.7.2/css/all.css"); */

.app__testimonial {
    z-index: 1;
    flex-direction: column;
    background: var(--color-black);

}

.app__testimonial-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__testimonial-menu {
    width: 100%;
    /* height: ; */
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}

* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box;
    font-family: 'Poppins', sans-serif; */
}

.app__testimonial__container {
    z-index: '1';
    /* background: linear-gradient(to bottom, #000428, black); */
    background: black;
    /* animation: background-color 60s ease ; */
    
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    

}

@keyframes background-color {
    0% {
        background: linear-gradient(to bottom, #000428, #004683);
    }

    25% {
        background: linear-gradient(135deg, #1a4223, #5ee95e);
    }

    50% {
        background: linear-gradient(to bottom, #421a31, #e95ed2);
    }

    100% {
        background: linear-gradient(-135deg, #fae37d, #881da8, #20668f);
    }

}


.cards {
    width: 300px;
    height: 400px;
    transform-style: preserve-3d;
    perspective: 500px;
    border: none;
    background-color: inherit;
}

.cards .face {
    position: relative;
    color: white;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1); */
    box-shadow: 0 5px 15px var(--color-golden);
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.06);
    transform-style: preserve-3d;
    transition: 0.5s;
    backface-visibility: hidden;
    border-top: 1px solid var(--color-golden);
    border-left: 1px solid var(--color-golden);
    /* border-top: 1px solid #ddd;
    border-left: 1px solid #ddd; */
    /* border-right: 1px solid #999;
border-bottom: 1px solid #999; */
}

.cards .face.front-face,
.cards .face.back-face {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cards .face.front-face .profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.cards .face.front-face .name {
    letter-spacing: 2px;
}

.cards .face.front-face .designation {
    font-size: 0.8rem;
    color: #ddd;
    letter-spacing: 0.8px;
}

.cards:hover .face.front-face {
    transform: rotateY(180deg);
}

.cards .face.back-face {
    position: absolute;
    background: rgba(255, 255, 255, 0.06);
    transform: rotateY(180deg);
    padding: 20px 30px;
    text-align: center;
    user-select: none;
}

.cards .face.back-face .fa-quote-left {
    position: absolute;
    top: 25px;
    left: 25px;
    font-size: 1.2rem;
}

.cards .face.back-face .fa-quote-right {
    position: absolute;
    bottom: 35px;
    right: 25px;
    font-size: 1.2rem;
}

.cards:hover .face.back-face {
    transform: rotateY(360deg);
}

.pt-3 {
    padding-top: 1rem!important;
}

/* .cards .face.front-face .profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}
.cards .face.front-face .name {
    letter-spacing: 2px;
}



.text-uppercase {
    text-transform: uppercase!important;
} */

/* .cards .face.front-face .designation {
    font-size: 0.8rem;
    color: #ddd;
    letter-spacing: 0.8px;
} */

@media(max-width: 991.5px) {
    .col-lg-4 {
        margin-top: 40px;
        margin-bottom: 20px;
    }
}

