.app__header {
  background-color: var(--color-black);
}

.app__header-h1 {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 117px;
  font-size: 70px;
}
.banner__style{
margin-top: 10rem;

}
.team__css{
  /* text-decoration: none; */
  z-index: -1;
  color: var(--color-golden);
  /* border-bottom: 1px solid var(--color-golden); */
}


.team__css:hover {
  /* color: var(--color-grey); */
  z-index: -1;
  cursor: pointer;
  /* border-bottom: 1px solid var(--color-grey); */
}

.video {
  position: fixed;
  margin-top: 90px;
  opacity: 0.1;
  width: 100%;
}

.mobile {
  display: none;
}
.app__header-img img {
  width: 80%;
}

@media screen and (min-width: 2000px) {
  .app__header-h1 {
    font-size: 180px;
    line-height: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .app__header-h1 {
    font-size: 90px;
  }
  .web {
    display: none;
  }

  .mobile {
    display: block;
  }
}

@media screen and (max-width: 650px) {
  .app__header-h1 {
    font-size: 70px;
    line-height: 87px;
  }

  .banner__style{
   margin-top: 0rem;
    
    }
}

@media screen and (max-width: 450px) {
  .app__header-h1 {
    font-size: 50px;
    line-height: 70px;
  }

  .banner__style{
    margin-top: 0rem;
    
    }
}
