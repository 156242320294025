.app__article {
    flex-direction: column;
    background: var(--color-black);
  }
  
  .app__article-title {
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .app__article-menu {
    width: 100%;
    margin: 2rem 0;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
  }

  .app__article__container{
    z-index: 1;
    display:flex;
    justify-content:space-around;
  }

  .app__social_wrapper_img{
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: space-evenly;

  }
  .social__media__card{
    max-width: 25%;
  }

  .sm__style{
    width: 18rem;
    left:0px;

  }

  .mot__style{
    width: 100%;
    
  }

  .sm__style:hover{
  cursor: pointer;
  }


  @media screen and (max-width: 480px) {
    .social__media__card{
      max-width: 100%;
    }


  .app__article__container{
    z-index: 1;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content:space-evenly ;
  }

  .app__social_wrapper_img{
    display: flex;
    flex-direction: column;
  }
  
  }

  