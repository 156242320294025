.app__team-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
}

.team__links {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  padding: 1px;
}


.custom__button__team {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 14px;
  padding: 0.5rem 1.5rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 2px;
}


.custom__button__beans {
  background-color: green;
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  width: 80vw;
  margin: 0 auto;
 
}

.btn {
  flex: 1 1 auto;
  margin: 10px;
  padding: 30px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
 /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #000;
  border-radius: 10px;
 }

/* Demo Stuff End -> */

/* <- Magic Stuff Start */

.btn:hover {
  background-position: right center; /* change the direction of the change here */
}

.btn-1 {
  background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
}

.btn-2 {
  background-image: linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%);
}

.btn-3 {
  background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%, #84fab0 100%);
}

.btn-4 {
  background-image: linear-gradient(to right, #a1c4fd 0%, #c2e9fb 51%, #a1c4fd 100%);
}

.btn-5 {
  background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 51%, #ffecd2 100%);
}

.custom__button__team__big {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 13px;
  padding: 0.5rem 1.5rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 2px;
}

.image__front {
  z-index: 1;
}

.team_img {
  margin-left: 12%;
  max-width: 75%;
}

.app__team-content_quote {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.app__team-content_quote img {
  width: 47px;
  height: 40px;
  margin: 0 1rem 1rem 0;
}

.app__team-sign {
  width: 100%;
  margin-top: 1rem;
}

.app__team-sign p:first-child {
  font-family: var(--font-base);
  font-weight: 400;
  font-size: 32px;
  line-height: 41.6px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: var(--color-golden);
}

.app__team-sign img {
  width: 250px;
  margin-top: 3rem;
}

@media screen and (min-width: 2000px) {
  .app__team-sign img {
    width: 370px;
  }

  .team_img {
    max-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .app__team-sign img {
    width: 80%;
    margin-left: 0%;
  }

  .team_img {
    max-width: 100%;
    margin-left: 0;
  }

  .custom__button__team__big {
    font-size: 12px;
  }
}