.imagecard {
  max-width: 100%;
}

@media screen and (min-width: 2000px) {
  .app__gallery-content button {
    margin-top: 2rem;
  }

  .app__gallery-content {
    min-width: 1000px;
    padding-right: 4rem;
  }

  .app__gallery-images_card {
    min-width: 400px;
    height: 600px;
  }

  .imagecard {
    width: 200%;
  }
}

@media screen and (max-width: 1150px) {
  .app__gallery {
    flex-direction: column;
  }

  .app__gallery-images {
    max-width: 100%;
    margin: 5rem 0;
  }

  .imagecard {
    width: 200%;
  }
}

@media screen and (max-width: 850px) {
  .app__gallery {
    padding: 4rem 0 4rem 4rem;
  }
  .imagecard {
    width: 200%;
  }
}

@media screen and (max-width: 650px) {
  .app__gallery {
    padding: 4rem 0 4rem 2rem;
  }

  .app__gallery-content {
    min-width: 100%;
  }

  .app__gallery-images_card {
    min-width: 240px;
    height: 600px;
  }

  .imagecard {
    width: 200%;
  }
}
